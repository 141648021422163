import { render, staticRenderFns } from "./Underline.vue?vue&type=template&id=30716442&scoped=true&"
import script from "./Underline.vue?vue&type=script&lang=js&"
export * from "./Underline.vue?vue&type=script&lang=js&"
import style0 from "./Underline.vue?vue&type=style&index=0&id=30716442&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30716442",
  null
  
)

export default component.exports