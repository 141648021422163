<template>
  <div class="underline">
    <hr class="black" />
    <hr class="accent" />
  </div>
</template>

<script>
export default {
  name: "Underline",
  props: {},
  components: {}
};
</script>

<style lang="scss" scoped>
@import "~vuetify-scss";
@import "~assets/scss/main";

.underline {
  hr {
    border: solid 1px;
    height: 1px;
    border: 0;
    margin-bottom: 2px;
  }
}
</style>
